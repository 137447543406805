import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/reset.css";
import "./assets/styles/style.css";
import Vue3DraggableResizable from "vue3-draggable-resizable";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VOtpInput from "vue3-otp-input";
import {
  faUserSecret,
  faFileSignature,
  faFloppyDisk,
  faCamera,
  faFile,
  faTrash,
  faDownload,
  faCalendar,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
library.add(faUserSecret, faFileSignature, faFloppyDisk, faCamera, faFile, faTrash, faDownload, faCalendar, faSpinner);

createApp(App)
  .use(router)
  .use(store)
  .use(Vue3DraggableResizable)
  .component('v-otp-input', VOtpInput)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
