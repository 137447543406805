import AuthService from '../services/AuthService';
const token = JSON.parse(sessionStorage.getItem('token'));
const initialState = token
  ? { status: { loggedIn: true }, token }
  : { status: { loggedIn: false }, token: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    verifyOTP({ commit }, data) {
      return AuthService.verifyOTP(data.shortUrl, data.token, data.otpCode).then(
        response => {
          commit('loginSuccess', response.data);
          return Promise.resolve(response);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, token) {
      state.status.loggedIn = true;
      state.token = token;
      sessionStorage.setItem('token', JSON.stringify(token));
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.token = null;
    }
  }
};