import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;

export default {
  sendOTPRequest(shortUrl, token) {
    return axios
      .post(API_URL + `/api/document/${shortUrl}/${token}/otp/send`);
  },
  verifyOTP(shortUrl, token, code) {
    return axios
      .post(API_URL + `/api/document/${shortUrl}/${token}/otp/verify`, {
        code
      });
  }
};
