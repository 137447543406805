import { createWebHistory, createRouter } from 'vue-router'

const routes = [
  {
    path: '/template/:id/:accessToken',
    name: 'FormTemplate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "FormClient" */ '../views/FormTemplate.vue'),
  },
  {
    path: '/updateTemplate/:id/:accessToken',
    name: 'FormUpdateTemplate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "FormClient" */ '../views/FormTemplate.vue'),
  },
  {
    path: '/doc/:id/:token',
    name: 'Formclient',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "FormClient" */ '../views/FormClient.vue'),
  },
  {
    path: '/sign',
    name: 'sign',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Signpad" */ '../views/Signpad.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes
});

export default router;
